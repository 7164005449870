export default {
  selectAccionCorrectora (Vue, idtanomaliaChecklist) {
    const tables = Vue.$offline.db.tables
    const accionCorrectora = Vue.$offline.accionCorrectora
    return Vue.$offline.db
      .select()
      .from(tables.accion_correctora)
      .innerJoin(accionCorrectora.dummyTable, accionCorrectora.pk.eq(accionCorrectora.dummyPk))
      .where(tables.accion_correctora.idtanomalia_checklist.eq(idtanomaliaChecklist))
      .orderBy(tables.accion_correctora.descripcion)
      .exec()
  },
  async selectFormData (Vue, id) {
    return (await Vue.$offline.accionCorrectoraOt.row(id)).accion_correctora_ot
  },
}
