import { TABLA } from '@/utils/consts'
import { ReadFileBase64 } from '@/utils/files'
import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, formData, idtanomaliaChecklistOt, idtfichero, idparteTrabajo) {
    const idaccionCorrectoraOt = uuidv4()
    await Vue.$offline.accionCorrectoraOt.insertSync({
      idaccion_correctora_ot: idaccionCorrectoraOt,
      idtanomalia_checklist_ot: idtanomaliaChecklistOt,
      idaccion_correctora: formData.accion_correctora?.accion_correctora?.idaccion_correctora,
      descripcion: formData.descripcion,
      descripcion_ampliada: formData.descripcion_ampliada,
      realizar: formData.realizar,
      observaciones: formData.observaciones,
      estado: 1,
      idarticulo: formData.idarticulo,
      idmaccion: formData.idmaccion,
      unidades: formData.unidades,
    })
    for (let file of formData.files) {
      await Vue.$offline.fichero.insertSync({
        idfichero: uuidv4(),
        nombre_fichero: file.name,
        idtabla: TABLA.accion_correctora_ot.idtabla,
        data: await ReadFileBase64(file),
        id: idaccionCorrectoraOt,
        idtfichero,
        type: file.type,
      })
    }
    if (formData.realizar && formData.accion_correctora?.accion_correctora?.idaccion_correctora) {
      Vue.$offline.accionCorrectoraOt.marcarRealizada(idaccionCorrectoraOt, idparteTrabajo)
    }
    return idaccionCorrectoraOt
  },
}
