<template>
  <b10-base>
    <ValidationProvider
      v-if="accionesCorrectoras.length > 0"
      v-slot="{ errors }"
      name="accion_correctora"
    >
      <b10-autocomplete
        v-model="form.accion_correctora"
        :items="accionesCorrectoras"
        item-text="accion_correctora.descripcion"
        label="Tipo de acción correctora"
        return-object
        clearable
        :error-messages="errors"
        @change="accionCorrectoraSelected"
      >
        <template #item="slotProps">
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap"
            >
              {{ slotProps.item.accion_correctora.descripcion }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </b10-autocomplete>
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        flat
        label="Descripción"
        :disabled="typeof(form.accion_correctora) !== 'undefined' && form.accion_correctora !== null"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.descripcion_ampliada"
      label="Descripción ampliada"
    />
    <v-checkbox
      v-model="form.realizar"
      label="Ha sido realizada durante la revisión"
    />
    <div
      v-show="form.realizar"
    >
      <b10-autocomplete-alert
        v-if="mensajeAlRealizarAccion"
      >
        {{ mensajeAlRealizarAccion }}
      </b10-autocomplete-alert>
    </div>
    <ValidationProvider
      v-if="isInsert && idtfichero && hasInsertAccionCorrectoraAdjuntoPerm"
      v-slot="{ errors }"
      name="files"
      :rules="formRules.files"
    >
      <v-file-input
        v-model="form.files"
        label="Ficheros"
        show-size
        prepend-icon="attach_file"
        chips
        multiple
        :error-messages="errors"
        @change="filesChange"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './AccionCorrectoraFormData'
import _ from '@/utils/lodash'
import { PARAMETRO } from '@/utils/consts'
import  { extend } from 'vee-validate'
import { size as fileSizeValidation } from 'vee-validate/dist/rules'

export default {
  mixins: [formMixin],
  props: {
    idtanomaliaChecklistOt: {
      type: [Number, String],
      required: true
    },
    idtfichero: {
      type: Number,
      default: 0,
    },
    hasInsertAccionCorrectoraAdjuntoPerm: {
      type: Boolean,
      default: true,
    },
    materialAfectado: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      form: {
        accion_correctora: null,
        descripcion: '',
        descripcion_ampliada: '',
        realizar: false,
        idarticulo: null,
        idmaccion: null,
        unidades: null,
        observaciones: '',
        files: [],
      },
      formRules: {
        descripcion: { required: true },
        files: { tamanioFicheroAdjunto: true },
      },
      accionesCorrectoras: [],
      articulo: '',
      maccion: '',
      tamanioMaxAdjuntoMB: 0,
      mensajeAlRealizarAccion: '',
    }
  },
  async created () {
    const maxAdjuntoMB = await this.$offline.parametro.valorParametro(PARAMETRO.parametros.TAMANIO_MAX_ADJUNTOS_MB)
    if (maxAdjuntoMB) {
      this.tamanioMaxAdjuntoMB = parseInt(maxAdjuntoMB)
    }
    extend('tamanioFicheroAdjunto', {
      message: 'El fichero es demasiado grande',
      validate: async (value) => {
        if (this.tamanioMaxAdjuntoMB > 0) {
          return fileSizeValidation.validate(value, { size: this.tamanioMaxAdjuntoMB * 1024 })
        } else {
          return true
        }
      }
    })
    const anomalia = await this.$offline.tanomaliaChecklistOt.row(this.idtanomaliaChecklistOt)
    if (anomalia.tanomalia_checklist_ot.idtanomalia_checklist) {
      this.accionesCorrectoras = await Data.selectAccionCorrectora(
        this, anomalia.tanomalia_checklist_ot.idtanomalia_checklist
      )
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      const accion_correctora = _.find(
        this.accionesCorrectoras,
        {
          accion_correctora: {
            idaccion_correctora: formData.idaccion_correctora,
          },
        }
      )
      if (accion_correctora) {
        formData.accion_correctora = accion_correctora
      }
      this.$set(this.form, 'accion_correctora', formData.accion_correctora)
      this.$set(this.form, 'descripcion', formData.descripcion)
      this.$set(this.form, 'descripcion_ampliada', formData.descripcion_ampliada)
      this.$set(this.form, 'realizar', formData.realizar)
      this.$set(this.form, 'observaciones', formData.observaciones)
      this.$set(this.form, 'idarticulo', formData.idarticulo)
      this.$set(this.form, 'idmaccion', formData.idmaccion)
      this.$set(this.form, 'unidades', formData.unidades)
    },
    async accionCorrectoraSelected (value) {
      this.mensajeAlRealizarAccion = ''
      if (value) {
        this.$set(this.form, 'descripcion', value.accion_correctora.descripcion)
        this.$set(this.form, 'idarticulo', value.accion_correctora.idarticulo)
        this.mensajeAlRealizarAccion =  await this.$offline.accionCorrectora.MensajeFacturacionAccionCorrectora(
          this, value, this.materialAfectado
        )
        this.$set(this.form, 'unidades', value.accion_correctora.unidades)
      } else {
        this.$set(this.form, 'descripcion', '')
        this.$set(this.form, 'idarticulo', null)
        this.$set(this.form, 'idmaccion', null)
        this.$set(this.form, 'unidades', null)
      }
    },
    filesChange (files) {
      this.$set(this.form, 'files', files)
    },

  }
}
</script>
